exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-gallery-motorization-jsx": () => import("./../../../src/pages/gallery/motorization.jsx" /* webpackChunkName: "component---src-pages-gallery-motorization-jsx" */),
  "component---src-pages-gallery-plantationshutters-jsx": () => import("./../../../src/pages/gallery/plantationshutters.jsx" /* webpackChunkName: "component---src-pages-gallery-plantationshutters-jsx" */),
  "component---src-pages-gallery-solarshades-jsx": () => import("./../../../src/pages/gallery/solarshades.jsx" /* webpackChunkName: "component---src-pages-gallery-solarshades-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

